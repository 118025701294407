import React, { useContext, useEffect, useState } from "react";

import "./Menus.css";

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IMenu } from "../../../types/Adm/Menu/IMenu";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { formataTimeStampLocal } from "../../../utils/date-utils";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import TableHeader from "../../../../components/elements/TableHeader";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Menus = () => {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [menus, setMenus] = useState<IMenu[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("MENU_ORDER,asc");

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchMenus(page: number, sort?: string) {
    let url = `v1/admin/menus/list?size=10&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IMenu>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setTotalPages(response.totalPages);
      setMenus(response.content);
      setCurrentPage(page + 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function excluiMenu(id: number) {
    let url = `/v1/admin/menus/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchMenus(0);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    searchMenus(0, sort);
  }, []);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchMenus(page - 1);
  };

  function handleClickEditar(menu: IMenu): void {
    navigate(`/area-logada/admin/edita-menu/${menu.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/admin/novo-menu`);
  }

  function handleClickExcluir(menu: IMenu): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir o menu ${menu.menuTitle}`)) {
      excluiMenu(menu.id);
    }
    return;
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchMenus(currentPage - 1, sortNovo);
  }

  const columns = [
    { displayName: "Id", columnName: "sm.ID" },
    { displayName: "Título", columnName: "sm.MENU_TITLE" },
    { displayName: "Url", columnName: "MENU_PATH" },
    { displayName: "Ícone", columnName: "MENU_ICON" },
    { displayName: "Ordem", columnName: "MENU_ORDER" },
    { displayName: "Menu Pai", columnName: "smp.MENU_TITLE" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Menus</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleOpenImport}
                  >
                    Importar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {menus.map((row, index) => (
                    <CustomStripedTableRow row={row.id} index={index}>
                      <CustomTableCell>{row.id}</CustomTableCell>
                      <CustomTableCell>{row.menuTitle}</CustomTableCell>
                      <CustomTableCell>{row.menuPath}</CustomTableCell>
                      <CustomTableCell>{row.menuIcon}</CustomTableCell>
                      <CustomTableCell>{row.menuOrder}</CustomTableCell>
                      <CustomTableCell>{row.menuParentTitle}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEditar} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Menus;

import { NavigateFunction } from "react-router-dom";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IPlan } from "../../../types/Adm/Plan/IPlan";
import { sendPost } from "../../../utils/httpUtils";

const planosService = {
  consultaTodosPlanos: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let url = `v1/admin/plans/list?size=1000&page=0`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IPlan>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response.content;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },
};

export default planosService;

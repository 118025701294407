import { useContext } from "react";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IRole } from "../../../types/Adm/Role/IRole";
import { IResponse } from "../../../types/Generic/IResponse";
import http from "../../http";
import { IRoles } from "../../../types/Adm/Role/IRoles";
import { sendPost } from "../../../utils/httpUtils";
import { NavigateFunction } from "react-router-dom";

const rolesService = {
  consultaTodasRoles: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let url = `v1/admin/roles/list?size=1000&page=0`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IRole>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response.content;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },

  verificaRole: function (rolesToCheck: String[], roles: IRoles[]) {
    if (roles.some((role) => rolesToCheck.includes(role.roleName))) {
      return true;
    }
    return false;
  },
};

export default rolesService;

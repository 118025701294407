import React, { useCallback, useContext, useEffect, useState } from "react";

import "./BankAccount.css";

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import { subDays } from "date-fns";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import { IBankAccount } from "../../../types/Ceb/IBankAccount";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BankAccount = () => {
  let navigate = useNavigate();

  const { selectedAccount, addMessages, setaLoading } = useContext(GlobalContext);

  const initialDays = 30;

  const [initialDate, setInitialDate] = useState<Date>(subDays(new Date(), initialDays));
  const [finalDate, setFinalDate] = useState<Date>(new Date());

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [openExport, setOpenExport] = React.useState(false);
  const handleOpenExport = () => setOpenExport(true);
  const handleCloseExport = () => setOpenExport(false);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [inputSearchValue, setInputSearchValue] = useState("");

  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("c.NAME,asc");

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchBankAccount(page: number, sort?: string, searchQuery?: string) {
    // console.log("searchQuery:", searchQuery);

    let url = `v1/ceb/bank-account/list?size=5&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      name: searchQuery,
    };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setTotalPages(response.totalPages);
      setBankAccounts(response.content);
      setCurrentPage(page + 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function deleteBankAccount(id: number) {
    let url = `/v1/ceb/bank-account/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchBankAccount(0, sort, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFez) {
        searchBankAccount(currentPage - 1, sort, value);
      }
      jaFez = true;

      return;
    }, 1000),
    [],
  ); // 1000 ms de delay

  useEffect(() => {
    searchBankAccount(0, sort);
  }, []);

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchBankAccount(page - 1, sort, debouncedSearchValue);
  };

  function handleClickEdit(BankAccount: IBankAccount): void {
    navigate(`/area-logada/ceb/edit-bank-account/${BankAccount.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/ceb/new-bank-account`);
  }

  function handleClickDelete(
    bankAccount: IBankAccount,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir a conta ${bankAccount.name}`)) {
      deleteBankAccount(bankAccount.id);
    }
    return;
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchBankAccount(currentPage - 1, sortNovo, debouncedSearchValue);
  }

  const columns = [
    { displayName: "Nome", columnName: "c.NAME" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack spacing={1}>
                <Titulo>Contas Bancarias</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                    disabled
                  >
                    Importar
                  </Button>

                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                    disabled
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {bankAccounts.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>{row.name}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEdit} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickDelete} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default BankAccount;
